import { memo } from 'react';
import PropTypes from 'prop-types';
import { DomHead, DomElement, DomBody } from 'sana/elements';

const Code = ({ model }) => {
  if (!model)
    return null;

  const { codeContent, headContent, bodyEndContent } = model;
  if (!codeContent && !headContent && !bodyEndContent)
    return null;

  return (
    <>
      {headContent && <DomHead content={headContent} />}
      {codeContent && <DomElement content={codeContent} />}
      {bodyEndContent && <DomBody content={bodyEndContent} />}
    </>
  );
};

Code.propTypes = {
  model: PropTypes.shape({
    codeContent: PropTypes.string,
  }),
};

export default memo(Code);
